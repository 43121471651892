.navigation {
  position: fixed;
  top: 10px; /* Move it to the top instead of bottom */
  right: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigation li {
  margin-bottom: 5px;
  cursor: pointer;
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  letter-spacing: 1px;
  font-size: xx-large;
  font-weight: 100;
  /* font-kerning: normal; */
  background: linear-gradient(90deg, #f7caae, #f0f2f4, rgb(197, 248, 200));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Keep the media query as it is */
@media (max-width: 768px) {
  .navigation {
    top: 50px;
    right: 50px;
  }
}
