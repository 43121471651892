/* AboutMeModal.module.css */
.aboutMeModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .aboutMeModalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .closeButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }

  .closeButtonContainer {
    text-align: center;
  }
  
  .image {
    max-width: 30%;
    height: auto;
  }

.aboutMeModalContent,
.galleryModalContent {
  padding: 20px;
}