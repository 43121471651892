/* AboutMeModal.module.css */
.gearModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  }

  .gearDesc {
    background-color: #3a3a38;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    -webkit-text-fill-color: #fff;
  }
  
  .gearModalContent {
    background-color: #f7f8f4;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    display: flex;
    grid-template-columns: repeat(3, 1fr);
  }
  
  .closeButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .imageIcon {
    /* Add styles for the image here */
    width: 100px; /* Adjust the width as needed */
    height: 100px;
    display: block;
    margin: 0 auto 20px; /* To center the image above the text */
  }