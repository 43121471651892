
  .banner-section {
    padding: 3px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
    
  .p1 {
    color: rgb(236, 29, 132);
  }
  
  .p2 {
    color: rgb(241, 232, 236);
    text-align: center;
  }
  
  .p3 {
    color: rgb(64, 119, 239);
  }

.p1,
.p2,
.p3 {
  font-size: large;
  margin-top: 5px;
  margin-bottom: 5px;
}
  
  .p1 a {
    /* Style the hyperlink when hovered */
    color: rgb(236, 29, 132); /* Replace with your desired color for the hovered state */
    text-decoration: none;
  }
  
  .p3 a {
    /* Style the hyperlink when hovered */
    color: rgb(64, 119, 239); /* Replace with your desired color for the hovered state */
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .p1,
    .p2,
    .p3 {
      font-size: medium;
    }
  }