/* AboutMeModal.module.css */
.closeButtonContainer {
  position: absolute;
  top: 10px;
  right: 10px; 
}

.closeButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.galleryModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.galleryModalContent {
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}

.imageIcon {
  width: 250px; 
  height: 250px;
  display: block;
  margin: 0 auto 5px; 
}