/* FullscreenImage.module.css */

.closeButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}
  
  .fullscreenContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: grid;
    align-items: center;
    justify-content: center;
    overflow: auto;
    z-index: 3; /* Make sure the fullscreen image container is above the gallery modal content */
  }
  
  .fullscreenImageWrapper {
    position: relative;
    z-index: 4; /* Ensure the fullscreen image is above the fullscreen container */
  }
  
  .fullscreenImage {
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
  