body {
  margin: 0;
  padding: 0;
  background-color: black;
  background-image: url("./images/background_img.jpg"); 
  background-size: auto 1100% ; 
  background-position: center top;
  background-repeat: no-repeat;
  overflow-y: auto;
}
